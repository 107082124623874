import React, { ReactNode,useEffect, useMemo, useState } from 'react'

import ThemeContext from './useThemeContext'

interface ThemeProviderProps {
    children: ReactNode
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const [currentTheme, setCurrentTheme] = useState<string>()

    useEffect(() => {
        setCurrentTheme(document.body.dataset.theme || 'light')
    }, [])

    const setThemeMode = ( newTheme ) => {
        document.body.dataset.theme = newTheme
        localStorage.setItem('theme', newTheme)
        setCurrentTheme(newTheme)
    }

    const value = useMemo(() => ({ currentTheme, setThemeMode }), [currentTheme])

    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    )
}

export default ThemeProvider
import { Core } from '@parsley_design_systems/design-system'
import React, { ReactNode } from 'react'

import { colorDarkMode } from '../../theme/colorDarkMode'
import { colorLightMode } from '../../theme/colorLightMode'
import { unitStyles } from '../../theme/unitStyles'
import A from '../A/A'
import ThemeProvider from '../ThemeProvider/ThemeProvider'

interface AppProps {
    children: ReactNode
}

const App = ({children} : AppProps) => {
    
    const renrezTheme = [
        {
            themeName: 'light', 
            theme: colorLightMode
        },
        {
            themeName: 'dark', 
            theme: colorDarkMode
        }
    ]
    
    return (
        <ThemeProvider>
            <Core colorTheme={renrezTheme} linkComponent={A} unitStyles={unitStyles}>
                {children}
            </Core>
        </ThemeProvider>
    )
}

export default App
import { BaseColor } from '@parsley_design_systems/design-system'

export const colorDarkMode: BaseColor = {
    brandPrimary: 'rgba(249, 173, 218, 1)',
    brandSecondary: 'rgba(44, 82, 89, 1)',
    brandTertiary: 'rgba(28, 52, 57, 1)',
    textPrimary: 'rgba(246, 248, 248, 1)',
    textSecondary: 'rgba(227,231,232, 1)',
    textTertiary: 'rgba(188, 198, 198, 1)',
    textHeading: 'rgba(215, 221, 222, 1)',
    textPrimaryInverse: 'rgba(246, 248, 248, 1)',
    textSecondaryInverse: 'rgba(227,231,232, 1)',
    textTertiaryInverse: 'rgba(188, 198, 198, 1)',
    textHeadingInverse: 'rgba(215, 221, 222, 1)',
    utility: 'rgba(99, 180, 193, 1)',
    utility2: 'rgba(51, 95, 103, 1)',
    utility3: 'rgba(139, 138, 111, 1)',
    utility4: 'rgba(219, 145, 189, 1)',
    backgroundPrimary: 'rgba(28, 62, 63, 1)',
    backgroundSecondary: 'rgba(19, 54, 55, 1)',
    backgroundTertiary: 'rgba(39, 71, 72, 1)',
    backgroundFourth: 'rgba(28, 52, 57, 1)',
    backgroundFifth: 'rgba(33, 61, 66, 1)',
    backgroundPrimaryInverse: 'rgba(19, 54, 55, 1)',
    backgroundSecondaryInverse: 'rgba(28, 62, 63, 1)',
    backgroundTertiaryInverse: 'rgba(39, 71, 72, 1)',
    backgroundModal: 'rgba(255, 255, 255, .4)',
    backgroundModalOff: 'rgba(255, 255, 255, 0)',
    backgroundLoading: 'rgba(215, 221, 222, 1)',
    interactive: 'rgba(249, 173, 218, 1)',
    interactiveSecondary: 'rgba(246, 248, 248, 1)',
    interactiveInverse: 'rgba(249, 173, 218, 1)',
    interactiveSecondaryInverse: 'rgba(246, 248, 248, 1)',
    interactiveBackground: 'rgba(131, 3, 79, 1)',
    interactiveBackgroundInverse: 'rgba(131, 3, 79, 1)',
    linkDefault: 'rgba(249, 173, 218, 1)',
    interactiveVisited: 'rgba(249, 173, 218, 1)',
    interactiveHover: 'rgba(251, 209, 234, 1)',
    interactiveActive: 'rgba(254, 235, 246, 1)',
    selected: 'rgba(195, 226, 231, 1)',
    selectedSecondary: 'rgba(246, 248, 248, 1)',
    selectedInverse: 'rgba(195, 226, 231, 1)',
    selectedBackground: 'rgba(44, 82, 89, 1)',
    selectedBackgroundSecondary: 'rgba(19, 54, 55, 1)',
    selectedBackgroundInverse: 'rgba(44, 82, 89, 1)',
    selectedBackgroundSecondaryInverse: 'rgba(19, 54, 55, 1)',
    borderForm: 'rgba(156, 171, 172, 1)',
    borderPresentation: 'rgba(66, 94, 95, 1)',
    scrollBar: 'rgba(139, 139, 139, 1)',
    opacityBackgroundDefault: 'rgba(0, 0, 0, var(--opacity-default))',
    opacityBoxShadow: 'rgba(0, 0, 0, var(--opacity-8))',
    boxShadowSmall: '0 var(--s-5) var(--s-4) var(--opacity-box-shadow)',
    boxShadowMedium: '0 var(--s-4) var(--s0) var(--opacity-box-shadow)',
    boxShadowLarge: '0 var(--s0) var(--s3) var(--opacity-box-shadow)',
    boxShadowXlarge: '0 var(--s3) var(--s6) var(--opacity-box-shadow)',
    criticalDefault: 'rgba(247, 180, 167, 1)',
    criticalBackground: 'var(--background-tertiary)',
    criticalText: 'rgba(247, 180, 167, 1)',
    warningDefault: '#rgba(241, 186, 118, 1)',
    warningBackground: 'var(--background-tertiary)',
    warningText: 'rgba(241, 186, 118, 1)',
    successDefault: 'rgba(135, 211, 190, 1)',
    successBackground: 'var(--background-tertiary)',
    successText: 'rgba(135, 211, 190, 1)',
    informationDefault: 'rgba(162, 200, 239, 1)',
    informationBackground: 'var(--background-tertiary)',
    informationText: 'rgba(162, 200, 239, 1)',
    dataVisualisation1Default: 'rgba(219, 80, 219, 1)',
    dataVisualisation1Background: 'rgba(106, 0, 106, 1)',
    dataVisualisation1Text: 'rgba(239, 174, 239, 1)',
    dataVisualisation2Default: 'rgba(143, 144, 11, 1)',
    dataVisualisation2Background: 'rgba(59, 60, 5, 1)',
    dataVisualisation2Text: 'rgba(203, 203, 27, 1)',
    dataVisualisation3Default: 'rgba(167, 108, 250, 1)',
    dataVisualisation3Background: 'rgba(72, 40, 115, 1)',
    dataVisualisation3Text: '#rgba(213, 184, 252, 1)',
    dataVisualisation4Default: 'rgba(175, 132, 39, 1)',
    dataVisualisation4Background: 'rgba(72, 54, 16, 1)',
    dataVisualisation4Text: 'rgba(223, 192, 126, 1)',
    dataVisualisation5Default: 'rgba(68, 158, 48, 1)',
    dataVisualisation5Background: 'rgba(28, 65, 20, 1)',
    dataVisualisation5Text: 'rgba(156, 210, 144, 1)',
    dataVisualisation6Default: 'rgba(229, 87, 153, 1)',
    dataVisualisation6Background: 'rgba(95, 37, 64, 1)',
    dataVisualisation6Text: 'rgba(253, 173, 210, 1)',
    dataVisualisation7Default: 'rgba(112, 81, 92, 1)',
    dataVisualisation7Background: 'rgba(72, 51, 59, 1)',
    dataVisualisation7Text: 'rgba(205, 193, 197, 1)',
    dataVisualisation8Default: 'rgba(4, 150, 198, 1)',
    dataVisualisation8Background: 'rgba(0, 62, 83, 1)',
    dataVisualisation8Text: 'rgba(139, 206, 229, 1)',
    dataVisualisation9Default: 'rgba(221, 105, 68, 1)',
    dataVisualisation9Background: 'rgba(92, 43, 28, 1)',
    dataVisualisation9Text: 'rgba(254, 178, 154, 1)',
    dataVisualisation10Default: 'rgba(143, 133, 176, 1)',
    dataVisualisation10Background: 'rgba(61, 44, 119, 1)',
    dataVisualisation10Text: 'rgba(198, 194, 215, 1)',
    formBackground: 'var(--background-secondary)',
    formBackgroundDisabled: 'rgba(39, 71, 72, 1)',
    formBackgroundError: 'var(--critical-background)',
    formBackgroundSelected: 'var(--selected-background)',
    formBackgroundSelectedInverse: 'var(--selected-background-inverse)',
    formText: 'var(--text-primary)',
    formTextInput: 'var(--text-primary)',
    formSupportText: 'var(--text-tertiary)',
    formValidationText: 'var(--critical-default)',
    formTextInputError: 'var(--text-primary)',
    formTextSelected: 'var(--selected)',
    formTextSelectedInverse: 'var(--selected-inverse)',
    formSupportTextError: 'var(--text-tertiary)',
    formTextPlaceholder: 'rgba(127, 127, 127, 1)',
    formTextDisabled: 'rgba(66, 94, 95, 1)',
    formTitleIdentifier: 'var(--text-primary)',
    formBorder: 'var(--border-form)',
    formBorderError: 'var(--critical-default)',
    formBorderDisabled: 'rgba(51, 82, 82, 1)',
    buttonPrimaryBackground: 'transparent',
    buttonPrimaryBorder: 'var(--interactive)',
    buttonPrimaryText: 'var(--interactive)',
    buttonPrimaryBackgroundHover: 'transparent',
    buttonPrimaryBorderHover: 'var(--interactive-hover)',
    buttonPrimaryTextHover: 'var(--text-primary-inverse)',
    buttonPrimaryBackgroundActive: 'transparent',
    buttonPrimaryBorderActive: 'var(--interactive-active)',
    buttonPrimaryTextActive: 'var(--text-primary-inverse)',
    buttonSecondaryBackground: 'transparent',
    buttonSecondaryBorder: 'var(--interactive)',
    buttonSecondaryText: 'var(--interactive)',
    buttonSecondaryBackgroundHover: 'transparent',
    buttonSecondaryBorderHover: 'var(--interactive-hover)',
    buttonSecondaryTextHover: 'var(--interactive-hover)',
    buttonSecondaryBackgroundActive: 'transparent',
    buttonSecondaryBorderActive: 'var(--interactive-active)',
    buttonSecondaryTextActive: 'var(--interactive-active)',
    buttonTertiaryBackground: 'none',
    buttonTertiaryBorder: 'none',
    buttonTertiaryText: 'var(--interactive)',
    buttonTertiaryBackgroundHover: 'none',
    buttonTertiaryBorderHover: 'none',
    buttonTertiaryTextHover: 'var(--interactive-hover)',
    buttonTertiaryBackgroundActive: 'none',
    buttonTertiaryBorderActive: 'none',
    buttonTertiaryTextActive: 'var(--interactive-active)',
    buttonPrimaryBackgroundDisabled: 'var(--form-background-disabled)',
    buttonPrimaryBorderDisabled: 'var(--form-border-disabled)',
    buttonPrimaryTextDisabled: 'var(--form-text-disabled)',
    buttonSecondaryBackgroundDisabled: 'var(--form-background-disabled)',
    buttonSecondaryBorderDisabled: 'var(--form-border-disabled)',
    buttonSecondaryTextDisabled: 'var(--form-text-disabled)',
    buttonTertiaryBackgroundDisabled: 'var(--form-background-disabled)',
    buttonTertiaryBorderDisabled: 'var(--form-border-disabled)',
    buttonTertiaryTextDisabled: 'var(--form-text-disabled)'
}
import { GatsbyLinkProps, Link } from 'gatsby'
import React, { forwardRef, Ref } from 'react'

type LinkProps = Omit<GatsbyLinkProps<object>, 'as' | 'href' | 'ref' | 'type' | 'to'>
export interface LinkComponentProps extends LinkProps {
    href?: any
    to?: any
}

const A = forwardRef<HTMLAnchorElement, LinkComponentProps>(
    ({ href, to, ...rest }, ref: Ref<HTMLAnchorElement>) => {
        if (!href && !to) return <a ref={ref} {...rest} />
        const aPath = to || href
        const hrefAsString = typeof aPath === 'string' ? aPath : aPath?.pathname
        if (hrefAsString && /^(https?:\/\/|\/\/)/i.test(hrefAsString)) {
            return <a href={hrefAsString} ref={ref} {...rest} />
        }
        // @ts-expect-error gatsby link
        return <Link ref={ref} to={aPath} {...rest} />
    }
)

A.displayName = 'A'

export default A
import { BaseColor } from '@parsley_design_systems/design-system'

export const colorLightMode: BaseColor = {
    brandPrimary: 'rgba(189, 54, 135, 1)',
    brandSecondary: 'rgba(152, 205, 214, 1)',
    brandTertiary: 'rgba(28, 62, 63, 1)',
    textPrimary: 'rgba(19, 54, 55, 1)',
    textSecondary: 'rgba(34,67,68, 1)',
    textTertiary: 'rgba(51, 82, 82, 1)',
    textHeading: 'rgba(28, 62, 63, 1)',
    textPrimaryInverse: 'rgba(255, 255, 255, 1)',
    textSecondaryInverse: 'rgba(227,231,232, 1)',
    textTertiaryInverse: 'rgba(188, 198, 198, 1)',
    textHeadingInverse: 'rgba(215, 221, 222, 1)',
    utility: 'rgba(51, 95, 103, 1)',
    utility2: 'rgba(78, 147, 159, 1)',
    utility3: 'rgba(139, 138, 111, 1)',
    utility4: 'rgba(205, 101, 163, 1)',
    backgroundPrimary: 'rgba(195, 226, 231, 1)',
    backgroundSecondary: 'rgba(229, 242, 245, 1)',
    backgroundTertiary: 'rgba(255, 255, 255, 1)',
    backgroundFourth: 'rgba(239, 241, 241, 1)',
    backgroundFifth: 'rgba(246, 248, 248, 1)',
    backgroundPrimaryInverse: 'rgba(44, 82, 89, 1)',
    backgroundSecondaryInverse: 'rgba(38, 71, 77, 1)',
    backgroundTertiaryInverse: 'rgba(33, 61, 66, 1)',
    backgroundModal: 'rgba(0, 0, 0, 0.6)',
    backgroundModalOff: 'rgba(0, 0, 0, 0)',
    backgroundLoading: 'rgba(215, 221, 222, 1)',
    interactive: 'rgba(173, 2, 105, 1)',
    interactiveSecondary: 'rgba(19, 54, 55, 1)',
    interactiveInverse: 'rgba(255, 255, 255, 1)',
    interactiveSecondaryInverse: 'rgba(254, 235, 246, 1)',
    interactiveBackground: 'rgba(173, 2, 105, 1)',
    interactiveBackgroundInverse: 'rgba(254, 235, 246, 1)',
    linkDefault: 'rgba(155, 44, 111, 1)',
    interactiveVisited: 'rgba(155, 44, 111, 1)',
    interactiveHover: 'rgba(142, 25, 95, 1)',
    interactiveActive: 'rgba(108, 19, 72, 1)',
    selected: 'rgba(51, 95, 103, 1)',
    selectedSecondary: 'rgba(19, 54, 55, 1)',
    selectedInverse: 'rgba(243, 249, 250, 1)',
    selectedBackground: 'rgba(51, 95, 103, 1)',
    selectedBackgroundSecondary: 'rgba(19, 54, 55, 1)',
    selectedBackgroundInverse: 'rgba(99, 180, 193, 1)',
    selectedBackgroundSecondaryInverse: 'rgba(215, 221, 222, 1)',
    borderForm: 'rgba(88, 113, 114, 1)',
    borderPresentation: 'rgba(215, 221, 222, 1)',
    scrollBar: 'rgba(110, 110, 110, 1)',
    opacityBackgroundDefault: 'rgba(0, 0, 0, var(--opacity-default))',
    opacityBoxShadow: 'rgba(0, 0, 0, var(--opacity-8))',
    boxShadowSmall: '0 var(--s-5) var(--s-4) var(--opacity-box-shadow)',
    boxShadowMedium: '0 var(--s-4) var(--s0) var(--opacity-box-shadow)',
    boxShadowLarge: '0 var(--s0) var(--s3) var(--opacity-box-shadow)',
    boxShadowXlarge: '0 var(--s3) var(--s6) var(--opacity-box-shadow)',
    criticalDefault: 'rgba(175, 28, 0, 1)',
    criticalBackground: 'rgba(253, 237, 234, 1)',
    criticalText: 'rgba(175, 28, 0, 1)',
    warningDefault: 'rgba(206, 113, 0, 1)',
    warningBackground: 'rgba(252, 239, 224, 1)',
    warningText: 'rgba(166, 74, 0, 1)',
    successDefault: 'rgba(11, 155, 117, 1)',
    successBackground: 'rgba(226, 244, 239, 1)',
    successText: 'rgba(9, 123, 93, 1)',
    informationDefault: 'rgba(12, 108, 205, 1)',
    informationBackground: 'rgba(231, 241, 251, 1)',
    informationText: 'rgba(12, 108, 205, 1)',
    dataVisualisation1Default: 'rgba(195, 0, 195, 1)',
    dataVisualisation1Background: 'rgba(251, 235, 251, 1)',
    dataVisualisation1Text: 'rgba(121, 0, 121, 1)',
    dataVisualisation2Default: 'rgba(113, 114, 9, 1)',
    dataVisualisation2Background: 'rgba(243, 243, 203, 1)',
    dataVisualisation2Text: 'rgba(68, 69, 5, 1)',
    dataVisualisation3Default: 'rgba(136, 76, 219, 1)',
    dataVisualisation3Background: 'rgba(244, 237, 254, 1)',
    dataVisualisation3Text: 'rgba(83, 46, 133, 1)',
    dataVisualisation4Default: 'rgba(139, 104, 31, 1)',
    dataVisualisation4Background: 'rgba(247, 240, 224, 1)',
    dataVisualisation4Text: 'rgba(84, 63, 19, 1)',
    dataVisualisation5Default: 'rgba(54, 125, 38, 1)',
    dataVisualisation5Background: 'rgba(232, 244, 229, 1)',
    dataVisualisation5Text: 'rgba(32, 75, 23, 1)',
    dataVisualisation6Default: 'rgba(182, 69, 122, 1)',
    dataVisualisation6Background: 'rgba(254, 235, 244, 1)',
    dataVisualisation6Text: 'rgba(110, 42, 74, 1)',
    dataVisualisation7Default: 'rgba(132, 102, 112, 1)',
    dataVisualisation7Background: 'rgba(243, 240, 241, 1)',
    dataVisualisation7Text: 'rgba(83, 60, 68, 1)',
    dataVisualisation8Default: 'rgba(0, 118, 158, 1)',
    dataVisualisation8Background: 'rgba(226, 243, 248, 1)',
    dataVisualisation8Text: 'rgba(0, 72, 96, 1)',
    dataVisualisation9Default: 'rgba(175, 83, 54, 1)',
    dataVisualisation9Background: 'rgba(255, 237, 231, 1)',
    dataVisualisation9Text: 'rgba(106, 50, 33, 1)',
    dataVisualisation10Default: 'rgba(115, 102, 156, 1)',
    dataVisualisation10Background: 'rgba(241, 240, 245, 1)',
    dataVisualisation10Text: 'rgba(71, 54, 125, 1)',
    formBackground: 'var(--background-tertiary)',
    formBackgroundDisabled: 'rgba(215, 221, 222, 1)',
    formBackgroundError: 'var(--critical-background)',
    formBackgroundSelected: 'var(--selected-background)',
    formBackgroundSelectedInverse: 'var(--selected-background-inverse)',
    formText: 'var(--text-primary)',
    formTextInput: 'var(--text-primary)',
    formSupportText: 'var(--text-tertiary)',
    formValidationText: 'var(--critical-default)',
    formTextInputError: 'var(--text-primary)',
    formTextSelected: 'var(--selected)',
    formTextSelectedInverse: 'var(--selected-inverse)',
    formSupportTextError: 'var(--text-tertiary)',
    formTextPlaceholder: 'rgba(127, 127, 127, 1)',
    formTextDisabled: 'rgba(120, 141, 141, 1)',
    formTitleIdentifier: 'var(--text-primary)',
    formBorder: 'var(--border-form)',
    formBorderError: 'var(--critical-default)',
    formBorderDisabled: 'rgba(188, 198, 198, 1)',
    buttonPrimaryBackground: 'var(--interactive)',
    buttonPrimaryBorder: 'var(--interactive)',
    buttonPrimaryText: 'var(--text-primary-inverse)',
    buttonPrimaryBackgroundHover: 'var(--interactive-hover)',
    buttonPrimaryBorderHover: 'var(--interactive-hover)',
    buttonPrimaryTextHover: 'var(--text-primary-inverse)',
    buttonPrimaryBackgroundActive: 'var(--interactive-active)',
    buttonPrimaryBorderActive: 'var(--interactive-active)',
    buttonPrimaryTextActive: 'var(--text-primary-inverse)',
    buttonSecondaryBackground: 'var(--background-tertiary)',
    buttonSecondaryBorder: 'var(--interactive)',
    buttonSecondaryText: 'var(--interactive)',
    buttonSecondaryBackgroundHover: 'var(--background-tertiary)',
    buttonSecondaryBorderHover: 'var(--interactive-hover)',
    buttonSecondaryTextHover: 'var(--interactive-hover)',
    buttonSecondaryBackgroundActive: 'var(--background-tertiary)',
    buttonSecondaryBorderActive: 'var(--interactive-active)',
    buttonSecondaryTextActive: 'var(--interactive-active)',
    buttonTertiaryBackground: 'transparent',
    buttonTertiaryBorder: 'transparent',
    buttonTertiaryText: 'var(--interactive)',
    buttonTertiaryBackgroundHover: 'transparent',
    buttonTertiaryBorderHover: 'transparent',
    buttonTertiaryTextHover: 'var(--interactive-hover)',
    buttonTertiaryBackgroundActive: 'transparent',
    buttonTertiaryBorderActive: 'transparent',
    buttonTertiaryTextActive: 'var(--interactive-active)',
    buttonPrimaryBackgroundDisabled: 'var(--form-background-disabled)',
    buttonPrimaryBorderDisabled: 'var(--form-border-disabled)',
    buttonPrimaryTextDisabled: 'var(--form-text-disabled)',
    buttonSecondaryBackgroundDisabled: 'var(--form-background-disabled)',
    buttonSecondaryBorderDisabled: 'var(--form-border-disabled)',
    buttonSecondaryTextDisabled: 'var(--form-text-disabled)',
    buttonTertiaryBackgroundDisabled: 'var(--form-background-disabled)',
    buttonTertiaryBorderDisabled: 'var(--form-border-disabled)',
    buttonTertiaryTextDisabled: 'var(--form-text-disabled)'
}